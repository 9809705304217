"use client"

import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import { useToast } from "@/components/ui/use-toast"
import { truncateWalletAddress } from "@/lib/utils"
import { useUser } from "@clerk/nextjs"
import { useEffect } from "react"
import { useAccount, useSignMessage } from "wagmi"

export function LinkWalletModal() {
    const { address, connector } = useAccount()
    const { signMessageAsync } = useSignMessage()
    const { user } = useUser()
    const { toast } = useToast()

    const handleLinkWallet = async () => {
        try {
            const message = "Sign a message to link your wallet to the Metacade Platform";
            const signed = await signMessageAsync({ account: address, message });

            if (!user) return;
            await user.update({
                unsafeMetadata: {
                    wallet: address,
                    message: message,
                    signature: signed,
                },
            });
            await user.reload();
            toast({
                title: "Wallet Linked",
                description: "Your wallet has been linked to your account.",
            });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            console.error("Error linking wallet:", error);
            toast({
                title: "Error",
                description: "Failed to link wallet. Please try again.",
                variant: "destructive",
            });
        }
    }

    useEffect(() => {
        let popupToast: any;
        const showToast = () => {
            if (!user?.unsafeMetadata?.wallet && connector) {
                popupToast = toast({
                    duration: 20000,
                    title: 'Your wallet is not yet linked to your account.',
                    className: 'text-white border-white border-primary',
                    variant: 'default',
                    action: (
                        <Button className="text-white" onClick={handleLinkWallet}>Link Wallet</Button>
                    )
                })
            }

        }

        // Only show the toast once when the component mounts
        showToast();

    }, [user?.unsafeMetadata?.wallet, connector]) // Empty dependency array to run only once on mount

    if (!address) return null

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button variant="outline" className="animate-pulse border-primary mx-auto block">Link Wallet To Get Started</Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="max-w-md mx-auto">
                <AlertDialogHeader>
                    <AlertDialogTitle className="text-center">Link Wallet {truncateWalletAddress(address)}</AlertDialogTitle>
                    <AlertDialogDescription className="text-center">
                        In order to use the Metacade Tournaments platform you must link your wallet.
                        This can be changed at any time in your profile settings.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="flex justify-center">
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction disabled={!user} onClick={handleLinkWallet}>Continue</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
