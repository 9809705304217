"use client"

import { Tournament } from '@/lib/schema';
import { formatUpperCaseString, groupTournamentsByGameId } from '@/lib/utils';
import { inviteUser } from '@/server-actions/invite-user';
import { useUser } from "@clerk/nextjs";
import { sendGAEvent } from '@next/third-parties/google';
import { DialogClose } from '@radix-ui/react-dialog';
import { useRef, useState } from 'react';
import { Dropdown } from '../Dropdown';
import { Button } from '../ui/button';
import { DialogFooter } from '../ui/dialog';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Select, SelectGroup, SelectItem, SelectLabel } from '../ui/select';
import { useToast } from '../ui/use-toast';

function InviteUser({ activeTournaments }: { activeTournaments: Tournament[] }) {
    const [selectedTournamentFromDropdown, setSelectedTournamentFromDropdown] = useState<Tournament | null>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const { toast } = useToast();
    const { user } = useUser();

    const metacadeTournaments = activeTournaments.filter(tournament => tournament.createdByUsername == "Metacade")
    const groupedTournaments = groupTournamentsByGameId(metacadeTournaments)

    const handleTournamentChange = (tournamentId: string) => {
        setSelectedTournamentFromDropdown(activeTournaments.find(t => t.tournamentId.toString() === tournamentId) || null);
    };

    const handleSubmit = async (formData: FormData) => {
        const result = await inviteUser(formData);
        if (result.success) {
            toast({
                title: "Success",
                description: result.message,
            });
            sendGAEvent("event", 'invite_user', { email: formData.get('email') as string });
        } else {
            toast({
                title: "Error",
                description: result.message,
                variant: "destructive",
            });
        }
    };

    return (
        <form ref={formRef} action={handleSubmit}>
            <div className="grid gap-4">
                <div className={`grid grid-cols-4 items-center gap-4`}>
                    <Dropdown name={selectedTournamentFromDropdown ? `${selectedTournamentFromDropdown.gameName} - Tournament ${selectedTournamentFromDropdown?.tournamentId}` : 'Select Tournament'}>
                        <Select onValueChange={handleTournamentChange}>
                            {Object.entries(groupedTournaments).map(([category, tournaments]) => (
                                <SelectGroup key={category}>
                                    <SelectLabel>
                                        <p className='text-purple-800 text-xl'>{category}</p>
                                    </SelectLabel>
                                    {Object.entries(tournaments).map(([gameId, tournaments]) => (
                                        <SelectItem className='cursor-pointer' key={gameId} value={tournaments[0].tournamentId.toString()}>
                                            {tournaments[0].communityName ? `${tournaments[0].communityName}: Tournament ${tournaments[0].tournamentId}` : `Tournament: ${tournaments[0].tournamentId}`} {`(${formatUpperCaseString(tournaments[0].network)})`}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            ))}
                        </Select>
                    </Dropdown>
                </div>
                <div className="grid w-full max-w-sm items-center gap-1.5">
                    <Label className='mt-1' htmlFor="email">Invite User</Label>
                    <Input
                        name="email"
                        className="mb-1"
                        type="email"
                        id="email"
                        placeholder="friend@gmail.com"
                        required
                    />
                </div>
                {/* Hidden input to store the selected tournament ID */}
                <input
                    type="hidden"
                    name="tournamentId"
                    value={selectedTournamentFromDropdown?.tournamentId.toString() || ''}
                />
                <DialogFooter>
                    <DialogClose asChild>
                        <Button
                            type="submit"
                            disabled={!user || !selectedTournamentFromDropdown}
                            className="text-white font-bold py-2 px-4 w-1/3"
                        >
                            Invite
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </div>
        </form>
    )
}

export default InviteUser