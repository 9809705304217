"use client"

import { useEffect, useState } from 'react';

const RotatingWords = ({ words }: { words: string[] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true); // Control the fade and move effect

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(false); // Start fade out
            setTimeout(() => {
                setCurrentIndex((currentIndex) => (currentIndex + 1) % words.length);
                setFade(true); // Reset fade in for next word
            }, 1000); // Transition halfway through the interval
        }, 2000); // Change word every 2 seconds

        return () => clearInterval(intervalId);
    }, [words.length]);

    return (
        <div className="hero-container items-center text-center my-8 font-heading">
            <h2 className={`hero glitch layers text-3xl  font-semibold tracking-tight  text-white mt-4 `} data-text={'OP_CODE'}>_INITIALIZE</h2>
            <div className={`hero glitch layers inline-block transition-all duration-1000 ${fade ? 'opacity-100 ' : 'opacity-0 '}`}>
                <span className="text-white text-3xl " data-text={words[currentIndex]}>{words[currentIndex]}</span>
            </div>
        </div>
    );
};

export default RotatingWords;
