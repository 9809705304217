"use client";

import { PendingLink, WalletObject } from "@/lib/schema";
import { truncateWalletAddress } from "@/lib/utils";
import { UserButton, useUser } from "@clerk/nextjs";
import { MobileIcon } from "@radix-ui/react-icons";
import { CheckCircleIcon, Wallet2Icon } from "lucide-react";
import { useEffect, useState } from "react";
import { useAccount, useSignMessage } from 'wagmi';
import { TelegramLink } from "./LinkTelegram";
import { Button } from "./ui/button";
import { useToast } from "./ui/use-toast";

const WalletLink: React.FC = () => {
    const { toast } = useToast();
    const { address } = useAccount();
    const { signMessageAsync } = useSignMessage();
    const { user } = useUser();

    const handleLinkWallet = async () => {
        try {
            const message = "Sign a message to link your wallet to the Metacade Platform";
            const signed = await signMessageAsync({ account: address, message });

            if (!user) return;
            await user.update({
                unsafeMetadata: {
                    wallet: address,
                    message: message,
                    signature: signed,
                },
            });
            await user.reload();
            toast({
                title: "Wallet Linked",
                description: "Your wallet has been linked to your account.",
            });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            console.error("Error linking wallet:", error);
            toast({
                title: "Error",
                description: "Failed to link wallet. Please try again.",
                variant: "destructive",
            });
        }
    }

    const handleUnlinkWallet = async () => {
        if (user) {
            await user.update({
                unsafeMetadata: {
                    wallet: null,
                },
            });
            await user.reload()
            toast({
                title: "Wallet Unlinked",
                description: "Your wallet has been unlinked from your account.",
            })
        }
    }

    const isWalletLinked = Boolean(user?.unsafeMetadata?.wallet || "");

    return (
        <div className="wallet-link space-y-4">
            <h1 className="font-bold text-xl">Link Wallet</h1>
            <p>In order to use your wallet on the Metacade Platform, you will need to link it to your account.</p>
            <p>Your currently connected wallet is: <strong>{truncateWalletAddress(address as `0x${string}`)}</strong></p>
            {<p className="font-bold animate-pulse">{address?.toLowerCase() != (user?.unsafeMetadata?.wallet as string || "").toLowerCase() ? 'The currently connected wallet is not the linked wallet. Ensure you are connected to the correct wallet.' : ''}</p>}

            <div className="flex items-center space-x-2">
                {isWalletLinked && (
                    <>
                        <p>Your account is currently linked to {truncateWalletAddress(String(user?.unsafeMetadata?.wallet))}</p>
                        <CheckCircleIcon height={25} width={25} className="text-green-400" />
                    </>
                )}
            </div>
            <div className="flex space-x-2">
                <Button onClick={handleLinkWallet} disabled={isWalletLinked}>
                    Link Wallet
                </Button>
                <Button onClick={handleUnlinkWallet} disabled={!isWalletLinked}>
                    Unlink Wallet
                </Button>
            </div>
        </div>
    );
};

const WalletLinkHeader = ({ linkInvite, walletObject }: { linkInvite: PendingLink | null, walletObject: WalletObject | null }) => {
    const [shouldOpenProfile, setShouldOpenProfile] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const { user } = useUser();

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get('confirm')) {
            setShouldOpenProfile(true);
        }
        setIsLoaded(true);
    }, [user, shouldOpenProfile]);

    return (
        <header>
            {isLoaded && (
                <UserButton defaultOpen={shouldOpenProfile}>
                    <UserButton.UserProfilePage label="account" />
                    <UserButton.UserProfilePage
                        label="Link Wallet"
                        url="link-wallet"
                        labelIcon={<Wallet2Icon height={15} width={15} className={`${!user?.unsafeMetadata.wallet ? 'animate-pulse' : ''}`} />}
                    >
                        <WalletLink />
                    </UserButton.UserProfilePage>
                    {walletObject?.wallet && <UserButton.UserProfilePage
                        label="Link Telegram"
                        url="link-telegram"
                        labelIcon={<MobileIcon height={15} width={15} className={`${!user?.unsafeMetadata.telegram ? 'animate-pulse' : ''}`} />}
                    >
                        <TelegramLink linkInvite={linkInvite} walletObject={walletObject} />
                    </UserButton.UserProfilePage>}
                    <UserButton.UserProfilePage label="security" />
                </UserButton>
            )}
        </header>
    );
};

export default WalletLinkHeader;
