"use client"

import { Tournament } from '@/lib/schema'
import { useUser } from '@clerk/nextjs'
import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { Modal } from './Modal'
import BuyCredits from './modal/BuyCredits'
import ClaimTokens from './modal/ClaimTokens'
import InviteUser from './modal/InviteUser'
import { Button } from './ui/button'
import { useToast } from './ui/use-toast'

function WalletActions({ claimableTournaments, possibleSelectedClaimableTournament, possibleSelectedSwapTournament, tournamentsThatAreNotEnded, showOnlyFirstTwoModals }: { network: string, claimableTournaments: Tournament[], possibleSelectedClaimableTournament: Tournament | null, possibleSelectedSwapTournament: Tournament | null, tournamentsThatAreNotEnded: Tournament[], showOnlyFirstTwoModals: boolean }) {
    const { toast } = useToast()
    const [connectionComplete, setConnectionComplete] = useState(false)
    const [claimOpen, setIsClaimOpen] = useState(false)
    const [buyCreditsOpen, setBuyCreditsOpen] = useState(false)
    const [inviteUser, setInviteUserOpen] = useState(false)
    const { isConnected, status, address } = useAccount()
    const { user } = useUser()

    useEffect(() => {
        if (!isConnected || !address || !user || !user.unsafeMetadata?.wallet) return
        if (isConnected && user?.unsafeMetadata.wallet && (user?.unsafeMetadata?.wallet as string).toLowerCase() != address?.toLowerCase()) {
            toast({
                title: "Your currently connected wallet is not linked to your account",
                variant: "destructive",
                duration: 8000,
                description: "Open your profile settings and ensure the correct wallet is linked to your account. Once linked, you can manage your credits and tournaments.",
            })
        }
    }, [isConnected, address, user?.unsafeMetadata.wallet])

    useEffect(() => {
        if (isConnected && user?.unsafeMetadata.wallet && (user?.unsafeMetadata?.wallet as string).toLowerCase() == address?.toLowerCase()) {
            setConnectionComplete(true)
        } else {
            setConnectionComplete(false)
        }
    }, [isConnected, status, address, user?.unsafeMetadata.wallet])

    return (
        <>
            <Modal
                isOpen={buyCreditsOpen}
                setOpen={setBuyCreditsOpen}
                heading={"Get Credits"}
                triggerButton={<Button
                    disabled={!connectionComplete}
                    variant="outline"
                    className={`relative hud-btn w-4/6 border-pink-600 shadow-inner hover:bg-pink-600 text-pink-600 text-bold px-4 py-2 flex items-center space-x-2 my-2 transition-transform duration-300 hover:scale-110`}
                >
                    Get Credits
                </Button>}
                description={"Swap Tokens for Credits, Credits allow you to compete in the selected Tournament. Note, credits do not carry over between tournaments."}
            >
                {possibleSelectedSwapTournament ?
                    <BuyCredits directBuy={false} setOpen={setBuyCreditsOpen} activeTournaments={tournamentsThatAreNotEnded} selectedTournament={possibleSelectedSwapTournament} beneficary={false} shouldRedirectToGameOnSuccess={showOnlyFirstTwoModals} />
                    : <p className='text-xs font-bold animate-pulse'>No Active Tournaments</p>}
            </Modal>
            {!showOnlyFirstTwoModals && (
                <>
                    {/* <Modal isOpen={claimCreditsOpen} setOpen={setClaimCreditsOpen} disabled={!connectionComplete} heading={"Claim Credits"} description={"Claim free credits you have earned from activities or free play and allocate them to an existing Metacade operated tournaments."}>
                        {<ClaimCredits setOpen={setClaimCreditsOpen} activeTournaments={tournamentsThatAreNotEnded} />}
                    </Modal> */}
                    <Modal isOpen={claimOpen} setOpen={setIsClaimOpen} heading={"Claim Tokens"} triggerButton={<Button
                        disabled={!connectionComplete}
                        variant="outline"
                        className={`relative hud-btn w-4/6 border-pink-600 shadow-inner hover:bg-pink-600 text-pink-600 text-bold px-4 py-2 flex items-center space-x-2 my-2 transition-transform duration-300 hover:scale-110 `}
                    >
                        Claim Tokens
                    </Button>} description={"So you outskilled the others huh? Select the tournament below to claim your winnings."} >
                        {possibleSelectedClaimableTournament ?
                            <ClaimTokens claimableTournaments={claimableTournaments} selectedTournament={claimableTournaments.find(s => s) || claimableTournaments[0]} />
                            : <p>No Completed Tournaments</p>}
                    </Modal>
                    <Modal isOpen={inviteUser} setOpen={setInviteUserOpen} heading={"Invite User"} triggerButton={<Button
                        disabled={!connectionComplete}

                        variant="outline"
                        className={`relative hud-btn w-4/6 border-pink-600 shadow-inner hover:bg-pink-600 text-pink-600 text-bold px-4 py-2 flex items-center space-x-2 my-2 transition-transform duration-300 hover:scale-110`}
                    >
                        Invite User
                    </Button>} description={"Invite a user to the platform and receive 3 free credits on a Metacade official tournament of your choice."}>
                        {<InviteUser activeTournaments={tournamentsThatAreNotEnded} />}
                    </Modal>
                </>
            )}
        </>
    )
}

export default WalletActions