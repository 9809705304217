"use client"

import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';
import { FundButton, getOnrampBuyUrl } from '@coinbase/onchainkit/fund';
import { useState } from 'react';
import { useAccount } from 'wagmi';


function BuyMetacadeButton() {
    const { address, isConnected, connector } = useAccount()
    const [isOpen, setIsOpen] = useState(false);

    const onrampBuyUrl = getOnrampBuyUrl({
        projectId: process.env.NEXT_PUBLIC_CDP_PROJECT_ID as string,
        addresses: { [address as string]: ['base'] },
        assets: ['ETH'],
        presetFiatAmount: 20,
        redirectUrl: "https://tournaments.metacade.co",
        fiatCurrency: 'USD'
    });

    const handleOpenChange = (open: boolean) => {
        setIsOpen(open);
    };

    return (
        <>
            <Dialog open={isOpen} onOpenChange={handleOpenChange}>
                <DialogTrigger asChild>
                    <Button
                        variant="outline"
                        className="relative hud-btn w-4/6 border-pink-600 shadow-inner hover:bg-pink-600 text-pink-600 text-bold px-4 py-2 flex items-center space-x-2 my-2 transition-transform duration-300 hover:scale-110"
                        onClick={() => setIsOpen(true)}
                    >
                        Get MCADE
                    </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Metacade Swap Interface</DialogTitle>
                        <DialogDescription>
                            Exchange tokens with a single click.
                        </DialogDescription>
                    </DialogHeader>
                    <h3 className="text-center">Onramp funds</h3>
                    <FundButton
                        fundingUrl={onrampBuyUrl}
                        className="relative w-full bg-primary text-black px-4 py-2 font-bold flex items-center justify-center space-x-2 my-2 text-sm transition-transform"
                        text='via Coinbase'
                        hideIcon={true}
                    />
                    <h3 className="text-center">Onchain swap</h3>
                    <div className="grid gap-4 py-4 ">
                        {address && isConnected && connector?.name == 'Coinbase Wallet' ? <Button className='text-black font-bold text-sm' onClick={() => {
                            window.open(`https://wallet.coinbase.com/assets/crypto/ETH%2FMCADE%2FETHEREUM_CHAIN%3A8453%2Ffalse%2F0xc48823ec67720a04a9dfd8c7d109b2c3d6622094?assetUUID=1bbb3c04-518a-4a47-b43c-03ee41d2a733&assetName=Metacade`, '_blank', 'noreferrer')
                        }}>via Smart Wallet</Button> : null}
                        <Button className='text-black font-bold text-sm' onClick={() => {
                            window.open(`https://app.uniswap.org/swap?chain=base&outputCurrency=0xc48823ec67720a04a9dfd8c7d109b2c3d6622094&inputCurrency=ETH`, '_blank', 'noreferrer')
                        }}>via Uniswap</Button>
                    </div>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setIsOpen(false)}>Close</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default BuyMetacadeButton
